<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col
                class="text-center"
            >
                <v-icon
                    size="100"
                >
                    mdi-close-circle
                </v-icon>
                <div class="text-center">
                    <h1>{{ $t('payment-error') }}</h1>
                    <span>{{ $t('payment-error2') }}</span>
                    <br>
                    <span>{{ $t('customerportal-addCreditCard-tryAgain') }}</span>
                </div>   
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import router from '../../router'

export default {
    data() {
        return {
        }
    },
    computed: {
    },
    created() { 
        setTimeout(() => {
            router.push({path: '/dashboard', name: 'CreditCardList'})
        }, 3500)
    },    
    methods: {
        close() {
            window.close()
        }
    }
}
</script>
